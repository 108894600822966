import { $, $$ } from '../helpers/query-selector';
import onScroll from '../helpers/on-scroll';
import { createFocusTrap } from 'focus-trap';
import transitionEnd from '../helpers/transition-end';

const header = $('.js-header');
const headerSmallClass = 'l-header--small';
const headerHiddenClass = 'l-header--hidden';
const headerHideThreshold = 300;

const nav = $('.js-nav');
const menuButton = $('.js-header-menu-button');
const navCloseButton = $('.js-nav-close-button');
const navActiveClass = 'l-nav--active';
const focusTrap = createFocusTrap(nav, {
  allowOutsideClick: true
});

async function openNav() {
  nav.classList.add(navActiveClass);
  header.classList.remove(headerSmallClass);

  // Focus trap sets focus on close button
  await transitionEnd(nav, 'opacity');
  focusTrap.activate();
}

function closeNav() {
  nav.classList.remove(navActiveClass);
  setHeaderSizeClass();

  // Focus trap sets focus on menu button again
  focusTrap.deactivate();
}

function initNav() {
  menuButton.addEventListener('click', openNav);
  navCloseButton.addEventListener('click', closeNav);

  document.addEventListener('keydown', e => {
    if (e.key === 'Esc' || e.key === 'Escape') closeNav();
  });
}

function setHeaderSizeClass() {
  if (window.scrollY <= 5) {
    header.classList.remove(headerSmallClass);
  } else {
    if (!nav.classList.contains(navActiveClass)) {
      header.classList.add(headerSmallClass);
    }
  }
}

function initHeader() {
  let prevScrollY = window.scrollY;

  onScroll(() => {
    // Scroll up
    if (window.scrollY < prevScrollY) {
      if (window.scrollY > headerHideThreshold) {
        header.classList.remove(headerHiddenClass);
      }
    }
    // Scroll down
    else {
      if (window.scrollY > headerHideThreshold) {
        if (!nav.classList.contains(navActiveClass)) {
          header.classList.add(headerHiddenClass);
        }
      }
    }

    // Reset at top
    setHeaderSizeClass();

    prevScrollY = window.scrollY;
  });

  setHeaderSizeClass();

  header.addEventListener('focusin', () => {
    header.classList.remove(headerHiddenClass);
  });
}

function init() {
  initHeader();
  initNav();
}

export default init;
