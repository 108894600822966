import { $, $$ } from '../helpers/query-selector';
import { saveData, reducedMotion } from '../helpers/variables';

function pauseVideo(video) {
  if (video.paused) return;

  video.pause();

  // clearTimeout(video.controlsTimeout);
}

function playVideo(video) {
  if (
    !video.paused ||
    !video.hasAttribute('data-autoplay') ||
    video.hasAttribute('controls') ||
    reducedMotion.matches ||
    saveData
  ) {
    return;
  }

  if (video.readyState === 0 || video.readyState === 1) {
    video.addEventListener('loadeddata', () => {
      video.play();
    });
  } else {
    video.play();
  }

  // Show controls if play not possible
  // video.controlsTimeout = setTimeout(() => {
  //   if (video.paused) {
  //     video.setAttribute('controls', 'true');
  //   }
  // }, 3000);
}

function init() {
  const videos = $$('.js-video video');
  if (!videos) return;

  const playObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const video = entry.target;

        if (entry.isIntersecting) {
          playVideo(video);
        } else {
          pauseVideo(video);
        }
      }
    },
    { rootMargin: '0% 0%' }
  );

  for (const video of videos) {
    if (
      video.hasAttribute('data-autoplay') &&
      (reducedMotion.matches || saveData)
    ) {
      video.setAttribute('controls', 'true');
    }

    playObserver.observe(video);
  }
}

export { init as default };
