import lazySizes from 'lazysizes';
import 'lazysizes/plugins/optimumx/ls.optimumx';
import { $$ } from '../helpers/query-selector';
import { saveData, viewport } from '../helpers/variables';

// Image lazyload
lazySizes.cfg.init = false;

function initImageLazyLoad() {
  // Load lower resolution images if saveData
  if (saveData) {
    const images = $$('.lazyload');
    for (const image of images) image.dataset.optimumx = 1;
  }

  // Remove transparent bg on lazyload
  document.addEventListener('lazyloaded', e => {
    const elementClasses = ['c-image__wrapper', 'c-iframe__wrapper'];

    for (const elementClass of elementClasses) {
      const wrapper = e.target.closest(`.${elementClass}`);
      if (wrapper) wrapper.classList.add(`${elementClass}--transparent`);
    }
  });

  lazySizes.init();
}

// Video lazyload
function initVideoLazyLoad() {
  const videos = $$('.js-video video');
  if (!videos) return;

  function fadeInVideo(video) {
    video.classList.remove('video-lazyload');
    video.classList.add('video-lazyloaded');
    video.parentNode.classList.add('c-video__wrapper--transparent');
  }

  const loadObserver = new IntersectionObserver(
    (entries, observer) => {
      for (const entry of entries) {
        const video = entry.target;

        // Load default or responsive src
        if (entry.isIntersecting) {
          if (video.dataset.src) {
            video.setAttribute('src', video.dataset.src);
          } else {
            if (viewport.m.matches) {
              video.setAttribute('src', video.dataset.srcDesktop);
            } else {
              video.setAttribute('src', video.dataset.srcMobile);
            }
          }

          video.load();

          video.addEventListener('loadeddata', () => {
            fadeInVideo(video);
          });

          // Fallback
          setTimeout(() => {
            fadeInVideo(video);
          }, 3000);

          loadObserver.unobserve(video);
        }
      }
    },
    {
      rootMargin: '25% 0%'
    }
  );

  for (const video of videos) {
    // Load initial video
    loadObserver.observe(video);

    // Update responsive video
    viewport.m.addEventListener('change', e => {
      loadObserver.observe(video);
    });
  }
}

function init() {
  initImageLazyLoad();
  initVideoLazyLoad();
}

export default init;
