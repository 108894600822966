import '../scss/app.scss'; // Vite requires css import in app.js

import { $ } from './helpers/query-selector';
import initSetVh from './modules/set-vh';
import initLazyLoad from './modules/lazyload';
import initVideo from './modules/video';
import initHeader from './modules/header';

async function init() {
  // Optional polyfills
  // const polyfills = [];
  // if (!('Feature' in window)) polyfills.push(import('feature-polyfill'));
  // await Promise.all(polyfills);

  // Immediate
  initSetVh();
  initLazyLoad();
  initVideo();
  initHeader();

  // Lazyloaded
  const modules = ['cookie-settings-button'];

  for (const module of modules) {
    import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  }

  // Conditionally lazyloaded
  const conditionalModules = {
    '.js-iframe': 'iframe',
    '.js-accordion': 'accordion',
    '.js-slideshow': 'slideshow',
    '.js-jobs': 'jobs',
    '.js-quotes-slider': 'quotes-slider',
    '.js-highlight-projects': 'highlight-projects',
    '.js-related-projects': 'related-projects',
    '.js-services-teaser': 'services-teaser'
  };

  for (const [selector, module] of Object.entries(conditionalModules)) {
    if ($(selector)) import(`./modules/${module}.js`).then(m => m.default()); // Vite supports dynamic import with variables
  }
}

init();
